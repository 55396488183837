import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CategoryListPopUp from './CategoryListPopUp';
import { LogoIcon } from '../appearance/icons/logo_icon';
import { LoginIcon } from '../appearance/icons/login_icon';
import { PhoneIcon } from '../appearance/icons/phone_icon';
import { COMPANY_PAGE, CONTACTS_PAGE, DELIVERY_PAGE, ELSI_CABINET_URL, MAIN_PAGE, makeUrl, MANUFACTURERS_PAGE, PRODUCTS_PAGE, TECHNICAL_DOCUMENTS_PAGE } from '../const/const';
import { ChevronIcon } from '../appearance/icons/chevron_icon';
import { getFetchRequest, GET_CURRENT_USER, MAKE_USER_REQUEST } from '../store/requests';
import { SearchHandler } from './sharedComponents/SearchHandler';
import { hintsService } from './sharedComponents/hintsService';
import { CustomNextRouter } from '../services/customRouter';

export default function Header() {
    const routerItem = useRouter();
    const tab_name = routerItem.pathname;
    const [categoriesListIsOpen, setCategoriesListIsOpen] = useState(false);
    const [activeMenuBottomTab, setActiveMenuBottomTab] = useState(tab_name);
    const [scroll, setScroll] = useState(0);
    const [userName, setUserName] = useState('');
    const [dataIsLoad, setDataIsLoad] = useState(false);
    const CustomRouter = new CustomNextRouter(routerItem);

    const succesGetUserInfoCallback = (result) => {
        setUserName(result.name);
        setDataIsLoad(true);
    };

    const errorGetUserDataCallback = (error) => {
        if (error.name === 'AbortError') {
            return null;
        } else {
            console.log(error);
        }
        setDataIsLoad(true);
    };

    const succesGetCurrentUserCallback = (response) => {
        if(response.id) {
            getFetchRequest(makeUrl([MAKE_USER_REQUEST, response.id, '/']), succesGetUserInfoCallback, errorGetUserDataCallback);
        } else {
            setDataIsLoad(true);
        }
    };

    
    useEffect(() => {
        localStorage.getItem('token')?
            getFetchRequest(makeUrl([GET_CURRENT_USER]), succesGetCurrentUserCallback, errorGetUserDataCallback)
            :
            setDataIsLoad(true);
    },[]);

    const handleClick = (e, url) => {
        e.preventDefault();
        if(url === '/cabinet') {
            window.location.href = ELSI_CABINET_URL;
        } else {
            setActiveMenuBottomTab(url);
            setCategoriesListIsOpen(false);
            routerItem.push(url);
        }
    };

    useEffect(() => {
        if(typeof window!==undefined) {
            window.addEventListener('scroll', isSticky);
        }
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    },[]);

    const isSticky = (e) => {
        setScroll(document.documentElement.scrollTop);
        document.documentElement.scrollTop>200&&setCategoriesListIsOpen(false);
    };

    const handleCloseSearchList = (e) => {
        e.stopPropagation();
        hintsService.clearHintsList();
    };

    return (
        <header className="header" onClick={handleCloseSearchList}>
            <nav className="menu-top container">
                <Link className={`menu-top__item ${tab_name===COMPANY_PAGE?'menu-top__item_active':''}`} href={COMPANY_PAGE}>О компании</Link>
                <Link className={`menu-top__item ${tab_name===DELIVERY_PAGE?'menu-top__item_active':''}`} href={DELIVERY_PAGE}>Способы доставки</Link>
                <Link className={`menu-top__item ${tab_name===CONTACTS_PAGE?'menu-top__item_active':''}`} href={CONTACTS_PAGE}>Контакты</Link>
                <div className="menu-top__phone">
                    <PhoneIcon />
                    <span className="menu-top__phone-text">
                        8-800-775-02-42
                    </span>
                </div>
            </nav>
            <div className="menu-middle container">
                <Link href={MAIN_PAGE} className="menu-middle__logo navigation-link"><LogoIcon handleClick={handleClick}/></Link>
                <SearchHandler CustomRouter={CustomRouter}/>
                {dataIsLoad?<Link href={userName?ELSI_CABINET_URL:'/login'} onClick={(e)=>userName?handleClick(e, '/cabinet'):handleClick(e, '/login')}  className="menu-middle__login navigation-link">
                    <LoginIcon />
                    <p>
                        <span className="menu-middle__login-text">{userName?`Здравствуйте, `:'Вход /'}</span>
                        <span className="menu-middle__login-text">{userName? userName :'Регистрация'}</span>
                    </p>
                </Link>:''}
            </div>
            <nav className={`menu-bottom container ${scroll>200?'menu-bottom_sticky':''}`} onMouseLeave={()=> setCategoriesListIsOpen(false)}>
                <Link className={`menu-bottom__item ${activeMenuBottomTab===PRODUCTS_PAGE?'menu-bottom__item_active':''} navigation-link`} onClick={(e)=>handleClick(e, '/products')} 
                    href={PRODUCTS_PAGE} onMouseEnter={() => scroll>200?null:setCategoriesListIsOpen(true)}>Каталог
                    <ChevronIcon nameOfClass={`menu-bottom__icon ${activeMenuBottomTab===PRODUCTS_PAGE?'menu-bottom__icon_active':''} ${scroll>200?'menu-bottom__icon_rotate':''}`} />
                </Link>
                <Link className={`menu-bottom__item ${activeMenuBottomTab===MANUFACTURERS_PAGE?'menu-bottom__item_active':''} navigation-link`} 
                    href={MANUFACTURERS_PAGE} onClick={(e)=>handleClick(e, MANUFACTURERS_PAGE)}>Производители</Link>
                <Link className={`menu-bottom__item ${activeMenuBottomTab===TECHNICAL_DOCUMENTS_PAGE?'menu-bottom__item_active':''} navigation-link`} 
                    href={TECHNICAL_DOCUMENTS_PAGE} onClick={(e)=>handleClick(e, TECHNICAL_DOCUMENTS_PAGE)}>Техническая документация</Link>
                {categoriesListIsOpen? <CategoryListPopUp setCategoriesListIsOpen={setCategoriesListIsOpen}/> : ''}
            </nav>
        </header>
    );
};