import React, { useEffect, useState } from "react";
import { errorCallback, makeUrl } from "../const/const";
import { useRouter } from 'next/router';
import { ChevronIcon } from "../appearance/icons/chevron_icon";
import { MAKE_CATEGORY_REQUEST, getFetchRequest } from "../store/requests";

const sortFunction = (a, b) => {
    if ( a.categoryName < b.categoryName ){
        return -1;
    }
    if ( a.categoryName > b.categoryName ){
        return 1;
    }
    return 0;  
};


const CategoryListPopUp = ({ setCategoriesListIsOpen }) => {
    const [categoriesList, setCategoriesList] = useState([]);
    const [activeCategory, setActiveCategory] = useState({});
    const router = useRouter();

    const succesCallback = (result) => {
        setCategoriesList(result);
    };

    useEffect(() => {
        getFetchRequest(makeUrl([MAKE_CATEGORY_REQUEST, '/']), succesCallback, errorCallback);
    }, []);

    const handleClick = (e, id) => {
        e.preventDefault();
        router.push(`/category/${id}`);
    }; 
    try {
        return (
            <div className="categories__popup" onMouseLeave={() => setCategoriesListIsOpen(false)}>
                <ul className="categories__popup-list">
                    <h2 className="categories__popup-title">Все категории</h2>
                    {categoriesList.length ? categoriesList.sort(sortFunction).map(it => {
                        return (
                            <li key={it.categoryId} className="categories__popup-item" onMouseEnter={() => setActiveCategory(it)} onClick={(e)=> handleClick(e, it.categoryId)}>
                                <span>{it.categoryName}</span>
                                {it.subCategories&&it.subCategories.length ? <ChevronIcon nameOfClass='categories__popup-item-icon' /> : ''}
                            </li>
                        );
                    }) : ''}
                </ul>
                {activeCategory['subCategories']&&activeCategory['subCategories'].length ?
                    <ul className="categories__popup-list categories__popup-list-subcategories">
                        <h2 className="categories__popup-title">{activeCategory.categoryName}</h2>
                        {activeCategory['subCategories'].sort(sortFunction).map(item => {
                            return (
                                <li key={item.categoryId} onClick={(e)=> handleClick(e, item.categoryId)} className="categories__popup-item">{item.categoryName}</li>
                            );
                        })}
                    </ul>
                    : ''}
            </div>
        );
    } catch (error) {
        return <div className="categories__popup categories__popup-error" onMouseLeave={() => setCategoriesListIsOpen(false)}>Ошибка загрузки</div>;
    }
};

export default CategoryListPopUp;
